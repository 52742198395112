const { protocol, host, pathname } = window.location;

const $activities = jQuery("[data-activities]");

function fetchFilteredContent($form) {
    const formData = new FormData($form[0]);
    const queryString = new URLSearchParams(formData).toString();
    const url = `${protocol}//${host}${pathname}?${queryString}`;

    $activities.addClass("loading");

    window.history.pushState("", "", url);

    $activities.load(url + " [data-activities] > *", queryString, function() {
        $activities.removeClass("loading");
    });
}

$activities.on("input", "[data-activities-filter]", function(e) {
    e.preventDefault();
    fetchFilteredContent($(this).closest("form"));
});

$activities.on("click", "[data-activities-reset]", function(e) {
    e.preventDefault();

    $activities.find("[data-activities-filter]").each(function(index, element) {
        element.checked = false;
    });
    fetchFilteredContent($activities.find("form"));
});
